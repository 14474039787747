import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Hero from "../components/heros/catering"

const SecondPage = () => (
  <Layout>
    <SEO title="Catering" />

    <Hero>
      <Container fixed style={{ textAlign: 'center', paddingTop: '4rem' }}>
        <Typography variant="h1" style={{ marginBottom: '2rem' }}>
          Catering
        </Typography>
        <Typography variant="h4" >
          Let us cater your next event.
        </Typography>
      </Container>
    </Hero>

    <Container fixed style={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
      <Typography variant="h6" component="p">
        Let Cluck cater you next event. Specializing in hand breaded chicken fingers, fries, wings, and home made pickles and if you have any other preferences just call and we would love to work something out for you.<br/><br />
        
        Made with love for your special event.
      </Typography>
    </Container>
  </Layout>
)

export default SecondPage
